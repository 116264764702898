import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const title = '上海市公安局执法状况统计分析';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title
    },
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/v1',
    name: 'IndexV1',
    component: () => import('../views/index/index.bak.vue')
  },
  {
    path: '/map1',
    name: 'Map1',
    component: () => import('../views/map/style1.vue')
  },
  {
    path: '/map2',
    name: 'Map2',
    component: () => import('../views/map/style2.vue')
  },
  {
    path: '/table2',
    name: 'Table2',
    component: () => import('../views/tables/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined ? title : to.meta.title as string
  next();
})
export default router
