import { createStore } from 'vuex'

const FACTORS = ['人员', '案件', '物品', '卷宗', '警情', '场所']
const CASE_COUNT = ['刑事立案', '刑事拘留', '移送审查起诉', '行政立案', '行政拘留', '其他行政处罚']

export default createStore({
  state: {
    orgCode: 310000,
    activeFactor: '人员',
    activeCaseType: '刑事立案',
    mapBusiKey: '案件',
    scores: [],
    problems: [],
    caseCount: [],
    caseReason: [],
  },
  getters: {
    getOrgCode(state) {
      return state.orgCode
    },
    getActiveFactor(state) {
      return state.activeFactor
    },
    getActiveCaseType(state) {
      return state.activeCaseType
    },
    getMapBusiKey(state) {
      return state.mapBusiKey
    },
    getScore(state) {
      return state.scores.filter(it => it.org_code == state.orgCode)
    },
    getProblems(state) {
      return state.problems.filter(it => it.org_code == state.orgCode && it.busi_type == state.activeFactor).slice(0, 5)
    },
    getCaseCount(state) {
      return state.caseCount.filter(it => it.org_code == state.orgCode)
    },
    getCaseReason(state) {
      return state.caseReason.filter(it => it.org_code == state.orgCode && it.busi_group == state.activeCaseType)
    },
    getMapBusiData(state) {
      // 六要素
      if (FACTORS.indexOf(state.mapBusiKey) != -1) {
        return state.scores.filter(it => it.org_code != 310000 && it.busi_type == state.mapBusiKey).sort((a, b) => b.score - a.score)
      } else if (CASE_COUNT.indexOf(state.mapBusiKey) != -1) {
        return state.caseCount.filter(it => it.org_code != 310000 && it.metric_name == state.mapBusiKey).sort((a, b) => b.score - a.score)
      } else {
        return []
      }
    }
  },
  mutations: {
    saveScores(state, { scores }) {
      state.scores = scores
    },
    saveProblems(state, { problems }) {
      state.problems = problems
    },
    changeFactor(state, { factor }) {
      state.activeFactor = factor
    },
    changeCaseType(state, { data }) {
      state.activeCaseType = data
    },
    saveCaseCount(state, { data }) {
      state.caseCount = data
    },
    saveCaseReason(state, { data }) {
      state.caseReason = data
    },
    changeOrg(state, { data }) {
      state.orgCode = data
    },
  },
  actions: {
    saveScores(ctx, payload) {
      ctx.commit('saveScores', payload)
    },
    saveProblems(ctx, payload) {
      ctx.commit('saveProblems', payload)
    },
    changeFactor(ctx, payload) {
      ctx.commit('changeFactor', payload)
    },
    changeCaseType(ctx, payload) {
      ctx.commit('changeCaseType', payload)
    },
    saveCaseCount(ctx, payload) {
      ctx.commit('saveCaseCount', payload)
    },
    saveCaseReason(ctx, payload) {
      ctx.commit('saveCaseReason', payload)
    },
    changeOrg(ctx, payload){
      ctx.commit('changeOrg', payload)
    }
  },
  modules: {
  }
})
