import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view';

// 引入全局css
import './assets/scss/style.scss';
// 引入图表（所有图标见 icon 目录下的 demo_index.html）
import './assets/icon/iconfont.css'
import './assets/dashboard/icon/iconfont.css'

import 'ant-design-vue/dist/reset.css';

// 引入 全局注册组件
import PublicComponent from '@/components/componentInstall';

const app = createApp(App)
app.use(Antd)
app.use(PublicComponent)
app.use(dataV)
app.use(store)
app.use(router)

// VXETable
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
app.use((app)=>app.use(VXETable))

app.mount('#app')
